// Release Switch Toggles: should not be used directly
// @see getReleaseSwitchToggleCookieName util
export const RELEASE_SWITCH_TOGGLE = 'release-switch'
export const RELEASE_SWITCH_TOGGLE_AT = 'release-switch-at'

// Release Toggles
export const RELEASE_TOGGLE_SAE_ONLY = 'sae-only'
export const RELEASE_TOGGLE_MARKETPLACE = 'marketplace'
export const RELEASE_TOGGLE_CART_CTA_DELAYED = 'cart-cta-delayed'
export const RELEASE_TOGGLE_ERX = 'erx'
export const RELEASE_TOGGLE_NOW = 'now'
export const RELEASE_TOGGLE_MP_CROSS_SELL = 'mp-cross-sell'
export const RELEASE_TOGGLE_VARIANT_ATTTRIBUTES_ENABLED = 'enabled'
export const RELEASE_TOGGLE_VARIANT_ATTRIBUTES = 'variant-attributes'
export const RELEASE_TOGGLE_HOME_ONE = 'home-one'
export const RELEASE_TOGGLE_HOME_ONE_CMS = 'h1-cms'
export const ERX_ADYEN_TOGGLE = 'erx-adyen'
export const ERX_ADYEN_MP_TOGGLE = 'erx-adyen-mp'
export const ERX_NOW_TOGGLE = 'erx-now'
export const APPLE_PAY_TOGGLE = 'wstra-2462'
export const HOME_ONE_ACCOUNT = 'wsssh-2348'
export const WELCOME_PAGE_TOGGLE = 'wscr-2386'
export const NEW_PUPF_TOGGLE = 'wscr-3167'

// Experiments
export const AD_FLYOUT_BANNER = 'cro-1333'
export const ODS_CLIENT_FLOW = 'ods-client-flow'
export const NEW_HEADER = 'header'
export const CSW_PRODUCT_TITLE = 'cro-780'
export const NEW_CDN = 'cro-1881'
export const SERP_NOW_PUSH = 'cro-121'
export const SERP_LOAD_MORE = 'cro-179'
export const VARIANT_ATTRIBUTES = 'variant-attributes'
export const TOP_DEALS_LABEL = 'cro-1073'
export const CTA_BUTTON_ON_ALL_CSW = 'cro-2052'
export const SPECIAL_SALE_OFFERS_MODAL = 'cro-1905'
export const CHANGE_GTM_SCRIPT_COM = 'cro-2468'
export const CHANGE_GTM_SCRIPT_FR = 'cro-3124'
export const CROSSSELL_LAYOUT = 'cro-1942'
export const NOW_HOMEPAGE_GB_FUNCTIONALITY = 'wstra-5032'
export const NOW_SERP_GB_FUNCTIONALITY = 'wstra-5031'
export const NOW_CATEGORY_GB_FUNCTIONALITY = 'wstra-5033'
export const SHIPPING_DELAY_NOTIFICATION = 'wstra-6359'
export const EXPERIMENT_PASSKEY_FAKE_DOOR = 'wscnl-8009-passkey-fake-door'
export const HEADER_SMALL_MENU_ITEM = 'wscnl-8443'

export const ORDER_HELP_MODAL_LINK = 'wsssh-2747'
export const DAMAGED_PRODUCTS_LINK = 'wstra-9315'

export const ALGOLIA_SPLIT = 'alg'

// ad-server experiments' name
export const AD_SERVER = 'ad-server'

export const RETAIL_MEDIA_CROSS_SELL_SERVICE = 'rmadt-954'
export const RETAIL_MEDIA_AD_SERVER_GLOO_ENVIRONMENT = 'rmadt-2188'
export const TOTAAL_KORTING_AB_TEST = 'wscnl-5650'
export const ALGOLIA_NEURAL_SEARCH = 'cro-4106'
// test different pricing strategies
export const DIFFERENT_PRICING_STRATEGY = 'wsawa-2829'
export const DIRECT_CHANNEL = 'direct'

export const ONE_TIME_PASSWORD_FAKE_DOOR_TEST = 'wscnl-6729-fake-door'

export const SHOW_CONTACT_INFORMATION = 'wscnl-7196'

export const SHOW_RATEPAY_FOR_ALL_ORDERS = 'wscnl-7469'
export const ALGOLIA_SUGGEST_OPTIMISATION = 'cro-4329'
