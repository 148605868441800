import {
  TENANT_AT,
  TENANT_BE,
  TENANT_CH,
  TENANT_COM,
  TENANT_FR,
  TENANT_IT,
} from 'shared/consts'

import type { TenantBasedExperiment } from './types'

export const CARTINTERMEDIATE_ERX_BANNER: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'cro-2940', mobile: 'cro-2941' },
}

export const CART_FREE_GIFT_PROMOTION: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3312',
  [TENANT_AT]: 'cro-3313',
  [TENANT_FR]: 'cro-3316',
  [TENANT_IT]: 'cro-3315',
  [TENANT_CH]: 'cro-3314',
  [TENANT_BE]: 'cro-4241',
}

export const EXIT_INTENT_POPUP_EXPERIMENT: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'cro-3102', mobile: 'cro-3103' },
  [TENANT_AT]: { desktop: 'cro-3105', mobile: 'cro-3104' },
  [TENANT_FR]: { desktop: 'cro-3109', mobile: 'cro-3108' },
  [TENANT_IT]: { desktop: 'cro-3106', mobile: 'cro-3107' },
  [TENANT_CH]: { desktop: 'cro-3110', mobile: 'cro-3111' },
}

export const ALL_PRODUCTS_FROM_SELLER_EXPERIMENT: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3214',
  [TENANT_AT]: 'cro-3215',
  [TENANT_IT]: 'cro-3609',
}

export const NFC_LANDING_PAGE_BANNERS: TenantBasedExperiment = {
  [TENANT_COM]: { desktop: 'wserx-199', mobile: 'wserx-199' },
}

export const CHANGE_CTA_TEXT_NAME_BE: TenantBasedExperiment = {
  [TENANT_BE]: 'cro-3358',
}

export const NEWSLETTER_MODAL: TenantBasedExperiment = {
  [TENANT_CH]: {
    desktop: 'cro-4137',
    mobile: 'cro-4137',
  },
  [TENANT_IT]: {
    desktop: 'cro-3970',
    mobile: 'cro-3970',
  },
}

export const AFTER_QUERY_SUGGEST_LAYER: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3883',
  [TENANT_AT]: 'cro-3885',
  [TENANT_FR]: 'cro-3888',
  [TENANT_IT]: 'cro-3887',
  [TENANT_CH]: 'cro-3889',
  [TENANT_BE]: 'cro-3886',
}

export const REMOVE_PACKSIZE_AND_CTA_FROM_SERP: TenantBasedExperiment = {
  [TENANT_COM]: 'cro-3791',
  [TENANT_AT]: 'cro-3792',
  [TENANT_IT]: 'cro-3793',
  [TENANT_FR]: 'cro-3794',
  [TENANT_CH]: 'cro-3795',
  [TENANT_BE]: 'cro-3796',
}

export const CART_FREE_SHIPPING_INDICATOR_EXPERIMENT: TenantBasedExperiment = {
  [TENANT_CH]: 'cro-3761',
}
